class HomeSliderItem extends Component {

    /**
     * @param {HTMLElement} el
     */
    constructor(el) {
        super(el);
        this.id = el.dataset.id;
        this.name = el.dataset.name;

        if (el.tagName.toLowerCase() === 'a') {
            el.addEventListener('click', () => this.onSliderClicked());
        } else {
            const button = el.querySelector('[data-open]');
            if (button) {
                button.addEventListener('click', () => this.onSliderClicked());
            }
        }
    }

    onSliderClicked() {
        EventBus.getInstance().dispatchEvent('homeSliderClicked', {id: this.id, name: this.name});
    }
}
